body{
  background-color: #f2f2f2;
}

.btn-primary{
  background: #005b49;
  font-size: 16px;
  border-radius: 4px;
  color: #fff;
  &:hover{background-color: #016753;}
}

.btn-secondary{
  background: #484848;
  font-size: 16px;
  border-radius: 4px;
  color: #fff;
}

footer{
  text-align: center;
  font-size: 10px;
}

.action-bar{  padding:15px; overflow:hidden;
  .btn{margin: 0 5px; font-size: 14px;}
  .btn-primary{ float:right; }
  .btn-secondary{ float:left;}
}

.panel-default{
  .panel-heading{
    background: #005b49 !important;
    font-size: 18px;
    text-transform: uppercase;
    color: #FFF !important;
    position: relative;
    span{
      font-size:10px;
      position:absolute;
      right:0;
      padding:5px;
      &#created{top:0;}
      &#updated{bottom:0;}
    }
  }
}

.table-actions{
  a{font-size: 12px;}
}

.errors{
  padding: 10px 35px;
}

.navbar{
  z-index:100;
}

.navbar-nav{
  background: #005b49;
  color: #fff;
}

.navbar .container .navbar-nav li a:hover{
  background: #fff;
  color:#005b49;
}

.glyphicon-ok-circle{color: #005b49;font-size: 20px;}
.glyphicon-ok-circle.orange{color: #ff7d00;}
.glyphicon-ok-circle.red{color: #ff0000;}
.glyphicon-ok-circle.blue{color: #2319ff;}

.glyphicon-exclamation-sign{
  color: #ff0000;font-size: 20px;
}

.panel-title{
  .glyphicon{
    padding: 0 !important;
    padding-left: 10px !important;
    font-size: 15px !important;
    position: relative !important;
  }
}

.alert-key{
  ul{ list-style-type: none;
    li{ display: inline-block; margin: 0 5px;
      .glyphicon{ font-size: 15px;}
    }
  }
}

.add-key-log-form{
  margin: 20px 0;
  a {
    display: block;
    width: 150px;
    margin: 0 auto;
    margin-top: 10px;
  }
    .error-message{
      display: none;
    }
  }
  a.submit-edit-property {
    display: block;
    width: 250px;
    margin: 0 auto;
    margin-top: 10px;
  }

.edit-property-form{
  display:none;
}
.add-property-form{
  margin: 20px 0;
  a {
    display: block;
    width: 150px;
    margin: 0 auto;
    margin-top: 10px;
  }
    .error-message{
      display: none;
    }
  }

  .input-error{
    border: 1px solid #ff0000 !important;
  }

a.remove{
  color: #ff0000 !important;
}

.areas_interest{
  display: inline-block !important;
  width: 90% !important;
}

.add-new-area{cursor: pointer;}
.area-list{
  list-style: none;
  .remove-area{ color: #ff0000 !important; cursor: pointer; margin-left: 10px;}
  li { display: inline-block; width: auto; padding: 5px; border: 1px solid #005b49; margin-left: 10px;}
}

.form-group .staff-image{
  margin: 5px;
}

.staff-image{
  max-height: 55px;
  width: auto;
}

.user-dropdown{
  display: inline-block !important;
}
.lock-warning{
  border: 1px solid #ff0000;
  padding: 5px;
  width: 95%;
  margin: 0 auto;
  background: #ffb9b9;
}

#add-new-file{
  display:block;width:100px; margin:0 auto; font-size:16px;color:#005b49;
}

ul.existing-files
  {
    li{ display: block; margin: 0 5px;
      a{font-size:16px;}
    }
  }

.remove-property-file{
  color: #ff0000;
  font-size:12px !important;
  margin-left:15px;
}