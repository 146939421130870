form{
  .form-group{overflow:hidden;}
  input[type="submit"]{width:auto;margin:0 auto;display:block;
  &:hover{background-color: #016753;}
  }
}

.autocomplete-suggestions { border: 1px solid #999; background: #FFF; overflow: auto; }
.autocomplete-suggestion { padding: 2px 5px; white-space: nowrap; overflow: hidden; }
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: #3399FF; }
.autocomplete-group { padding: 2px 5px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }

.table-actions{
  a { cursor: pointer;}
  form { display: inline-block; margin-left: 5px;}
}