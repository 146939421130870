.add-message-form{
  textarea{width:100%;}
  a{display:block;width:150px;margin:0 auto;}
}

.tenant_td{width:10px;}
.tenant_td.issue{background: #ff564d;}

.property-fund-tabs{
  li{border: 1px solid #ccc;border-bottom: none;
    a{ color: #005b49;}
    a.active{color: #000; background: #ccc; border-radius: 0; margin: 0;}
  }
}