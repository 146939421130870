@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.navbar{ background-color:#005b49 !important;border-radius:0;color:#fff;font-size:15px;height:75px;
  .container{height:100%;
    .navbar-brand{ padding:10px;
      .logo{height:50px;width:auto;}
    }
    .navbar-nav{
      li{color:#fff;
        a{color:#fff;}
      }
      .dropdown-menu{
        background:#005b49;
      }
    }
    .navbar-collapse{
      padding-top: 10px;
    }  
  }
}
